 <!--
 * @FilePath: \zsw_new_edition\src\components\SuList.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-23 14:04:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-03-08 14:55:08
-->
<!--模型列表组件-->
<template>
  <div class="container" v-if="psList.length > 0">
    <div class="left">
      <div class="leftTop">
        <a :href="psList[0].url" target="_blank"
            class="suItem"
        >
          <div class="suItemImg">
            <img :src="psList[0].src" alt="" />
          </div>
          <div class="suItemTitle flex-sb" :ref="0">
						<div class="text">{{ psList[0].title }}</div>
						<div class="count"> +{{ psList[0].count}}</div>
					</div>
        </a>
      </div>
      <div class="leftBottom">
        <div class="leftBottomLeft">
          <a :href="psList[1].url" target="_blank"
              class="suItem"
          >
            <div class="suItemImg">
              <img :src="psList[1].src" alt="" />
            </div>
						<div class="suItemTitle flex-sb" :ref="1">
							<div class="text">{{ psList[1].title }}</div>
							<div class="count"> +{{ psList[1].count}}</div>
						</div>
          </a>
        </div>
        <div class="leftBottomRight">
          <a :href="psList[2].url" target="_blank"
              class="suItem"
          >
            <div class="suItemImg">
              <img :src="psList[2].src" alt="" />
            </div>
            <div class="suItemTitle flex-sb" :ref="2">
							<div class="text">{{ psList[2].title }}</div>
							<div class="count"> +{{ psList[2].count}}</div>
						</div>
          </a>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="rightTop">
        <a :href="psList[3].url" target="_blank"
            class="suItem"
        >
          <div class="suItemImg">
            <img :src="psList[3].src" alt="" />
          </div>
          <div class="suItemTitle flex-sb" :ref="3">
						<div class="text">{{ psList[3].title }}</div>
						<div class="count"> +{{ psList[3].count}}</div>
					</div>
        </a>
      </div>
      <div class="rightBottom">
        <a :href="psList[4].url" target="_blank"
            class="suItem"
        >
          <div class="suItemImg">
            <img :src="psList[4].src" alt="" />
          </div>
          <div class="suItemTitle flex-sb" :ref="4">
						<div class="text">{{ psList[4].title }}</div>
						<div class="count"> +{{ psList[4].count}}</div>
					</div>
        </a>
      </div>
    </div>
<!--    <el-skeleton-->
<!--        style="width: 80%"-->
<!--        :loading="loading"-->
<!--        animated-->
<!--        :count="psList.length + 4"-->
<!--    >-->
<!--      <div slot="template">-->
<!--        <el-skeleton-item-->
<!--            variant="image"-->
<!--            style="width: 3.2rem; height: 2.7rem; margin-bottom: 0.4rem"-->
<!--        />-->
<!--      </div>-->
<!--      <div class="psList" v-if="psList.length > 0">-->
<!--        <router-link-->
<!--            :to="{ path: '/ps/detail', query: { detailId: item.id } }"-->
<!--            target="_blank"-->
<!--            class="suItem"-->
<!--            v-for="(item, index) in psList"-->
<!--            :key="index"-->
<!--        >-->
<!--          <div class="suItemImg"-->
<!--               @mouseleave="hideSuItemTitle(index)"-->
<!--               @mouseover="showSuItemTitle(index)">-->
<!--            <img :src="item.img" alt="" />-->
<!--          </div>-->
<!--          <div class="suItemTitle" :ref="index">{{ item.title }}</div>-->
<!--        </router-link>-->
<!--        <div style="height: 0; width: 3.2rem"></div>-->
<!--        <div style="height: 0; width: 3.2rem"></div>-->
<!--        <div style="height: 0; width: 3.2rem"></div>-->
<!--      </div>-->
<!--      <div v-else>-->
<!--        <el-empty description="没有内容哦"></el-empty>-->
<!--      </div>-->
<!--    </el-skeleton>-->
  </div>

</template>

<script>
export default {
  name: "HotPsList",
  props: {
    psList: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods:{
    showSuItemTitle(){
      this.$nextTick(()=>{
        // this.$refs[index].style.display = 'block'
      })
    },
    hideSuItemTitle(){
      // this.$refs[index].style.display = 'none'
    },
  },
  created() {},
};
</script>

<style scoped>
/*@media only screen and (min-width: 2000px){*/
/*  .container {*/
/*    width:100%;*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*  }*/
/*  .left{*/
/*    width:60%;*/
/*    height: 7.52rem;*/
/*    margin-right: 1%*/
/*    !*border:1px solid red*!*/
/*  }*/
/*  .leftTop{*/
/*    height:4.6rem;*/
/*    width:100%;*/
/*    border-radius:10px*/
/*  }*/
/*  .leftBottom{*/
/*    width:100%;*/
/*    height:2.6rem;*/
/*    margin-top:0.32rem;*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*  }*/
/*  .leftBottomLeft{*/
/*    width:60%;*/
/*    height:2.6rem;*/
/*    border-radius:10px;*/
/*    margin-right: 2%*/
/*  }*/
/*  .leftBottomRight{*/
/*    width:39%;*/
/*    height:2.6rem;*/
/*    border-radius:10px*/
/*  }*/
/*  .right{*/
/*    width:38%;*/
/*    height:7.52rem;*/
/*  }*/
/*  .rightTop{*/
/*    width:100%;*/
/*    height:2.6rem;*/
/*    border-radius:10px*/
/*  }*/
/*  .rightBottom{*/
/*    width:100%;*/
/*    height:4.6rem;*/
/*    margin-top:0.32rem;*/
/*    border-radius:10px*/
/*  }*/
/*}*/
  .container {
    display: flex;
    justify-content: space-between;
  }
  .left{
    width:9.6rem;
    height: 7.52rem;
    /*margin-right: 32px*/
    /*border:1px solid red*/
  }
  .leftTop{
    height:4.6rem;
    width:9.6rem;
    border-radius:0.1rem
  }
  .leftBottom{
    width:9.6rem;
    height:2.6rem;
    margin-top:0.32rem;
    display: flex;
    justify-content: space-between;
  }
  .leftBottomLeft{
    width:5.32rem;
    height:2.6rem;
    border-radius:10px
  }
  .leftBottomRight{
    width:4.04rem;
    height:2.6rem;
    border-radius:10px
  }
  .right{
    width:6.8rem;
    height:7.52rem;
  }
  .rightTop{
    width:6.8rem;
    height:2.6rem;
    border-radius:0.1rem
  }
  .rightBottom{
    width:6.8rem;
    height:4.6rem;
    margin-top:0.32rem;
    border-radius:0.1rem
  }


.suItemTitle {
  padding: 0 0.3rem;
  color: #FFFFFF;
  font-size: 0.20rem;
  /*margin-top: 0.2rem;*/
  width: 100%;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow:ellipsis;
  position: relative;
  bottom: 0.5rem;
  height:0.5rem;
  line-height: 0.5rem;
  background: linear-gradient(360deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.01) 100%);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  /*display: none;*/
}
.text {
	flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow:ellipsis;
}
.count {
	font-size: 0.24rem;
	font-weight: 700;
	color: rgba(255, 255, 255, 1);
}

.suItem {
  width: 100%;
  padding-right: 0.2rem;
  margin-bottom: 0.4rem;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  position: relative;
  border-radius: 10px
}
.suItem:hover {
  transform: translateY(-6px);
  -webkit-transform: translateY(-6px);
  -moz-transform: translateY(-6px);
}

.suItemImg {
  width: 100%;
  height: 100%;
  border-radius:10px;
  overflow: hidden;
}
.suItemImg img {
  width: 100%;
  height: 100%;
  border-radius:10px;
  transition: all 0.6s;
  /* height: 2.4rem; */
}
.suItemImg img:hover {
  transform: scale(1.2);
  /* height: 2.4rem; */
}
/*::v-deep .psList {*/
/*  width: 100%;*/
/*  display: flex;*/
/*  justify-content: space-between;*/
/*  flex-wrap: wrap;*/
/*}*/
</style>
