<!--
 * @FilePath: \zsw_new_edition\src\components\IndexAD.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-23 14:04:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-01-21 17:28:43
-->
<!--首页广告部分组件-->
<template>
  <div class="indexADList">
    <div class="indexADItem" v-for="(item,index) in adList" :key="index">
      <a :href="item.url" target="_blank" rel="noopener noreferrer">
        <img
        :src="item.src"
        alt=""
      />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props:["adList"],
  name: "IndexAD",
};
</script>

<style scoped>
.indexADList {
  margin: 0.2rem 0;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  padding: 0 0.1rem;
}

.indexADItem {
  width: 15.6%;
  height: 1.8rem;
  overflow: hidden;
  border-radius: 0.06rem;
}
.indexADItem:not(:first-child) {
  margin-left: 0.2rem;
}

.indexADItem img {
  width: 100%;
  background: coral;
  transition: all 0.4s;
  -moz-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  cursor: pointer;
  border-radius: 0.06rem;
}
.indexADItem img:hover {
  transform: scale(1.2);
}
</style>