<template>
  <div class="container">
		<div class="floorTitleBox">
			<div class="df_Yc">
				<span class="floorName">{{ content.title }}</span>
			</div>
		</div>
		<div class="salesList">
			<ul class="salesUl" v-if="content.type == 1">
				<li class="salesLi" v-for="item in hotCourseList" :key="item.id">
					<div class="desc">{{item.title}}</div>
					<div class="progress" :class="`content${content.type}`">
						<el-progress :text-inside="true" :stroke-width="26" :percentage="percentage(hotCourseList, item)" :format="format"></el-progress>
					</div>
				</li>
			</ul>
			<ul class="salesUl" v-else>
				<li class="salesLi" v-for="item in hotMaterialList" :key="item.id">
					<div class="desc">{{item.title}}</div>
					<div class="progress" :class="`content${content.type}`">
						<el-progress :text-inside="true" :stroke-width="26" :percentage="percentage(hotMaterialList, item)" :format="format"></el-progress>
					</div>
				</li>
			</ul>
		</div>
  </div>
</template>

<script>
  export default {
  name: "hotSoftware",
  props:{
		content: {
			type: Object,
			default: () => {},
		},
    hotMaterialList: {
      type: Array,
      default: () => [],
    },
    hotCourseList: {
      type: Array,
      default: () => [],
    },
		
  },
  methods: {
		// 计算进度条位置
		percentage(list, item) {
			return (item.income/list[0].income) * 100
		},
		// 计算数量
		format(percentage) {
			let data = 0
			if (this.content.type == 1) {
				data = (this.hotCourseList[0].income * percentage) / 100
			} else{
				data = (this.hotMaterialList[0].income * percentage) / 100
			}
			data = data.toFixed()
			if (JSON.stringify(data).length > 6) {
				data = (data/10000).toFixed(2) + '万'
			}
			return data
		}
	},
  data(){
    return {}
  },
  mounted(){
	}
}
</script>

<style scoped>
.container{
  width: 100%;
  display: flex;
  flex-direction: column;
}
/deep/ .el-progress-bar__outer{
	background: none;
}
.content1 /deep/.el-progress-bar__inner {
	background: linear-gradient(90deg, rgba(85, 129, 241, 1) 0%, rgba(74, 91, 252, 1) 100%);
}
.content2 /deep/.el-progress-bar__inner {
	background: linear-gradient(90deg, rgba(255, 203, 82, 1) 0%, rgba(255, 123, 2, 1) 100%);
}

.floorName {
  color: #333333;
  font-size: 0.24rem;
  font-weight: bold;
}
.salesUl {
	margin-top: 0.18rem;
	padding: 0.3rem;
	border-radius: 0.16rem;
	background: rgba(246, 246, 254, 1);
}
.salesLi {
	display: flex;
	margin-bottom: 0.2rem;
}
.desc{
	width: 2.35rem;
	margin-right: 0.24rem;
	font-weight: 500;
	letter-spacing: 0px;
	color: rgba(44, 44, 52, 1);
	text-align: right;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.progress {
	flex: 1;
}
</style>
