<template>
  <div>
    <div class="main_Div">
      <SlideShow :bannerList="bannerList" @bannerH="getBannerH" ref="mychild"></SlideShow>
      <div class="indexSearchPositioner" :style="{height:bannerHeight+'px' }">
        <IndexSearch @tag="getTagFormSon"></IndexSearch>
      </div>
      <IndexAD :adList="adList"></IndexAD>
      <div v-for="(item,index) in floorData" :key="index">
        <IndexFloor :content="item"></IndexFloor>
      </div>
    </div>
    <!-- <div class="footerBg">
      <div class="left">
        <img src="../../assets/image/leftTitleLogo.png" class="leftTitleBox"/>
        <div class="leftContentBox">
          <ul class="uploaderEarningsList">
            <li class="df_Yc uploaderEarningsItem">
              <div class="f16">排名</div>
              <div class="f16">用户名</div>
              <div class="f16">累计收益</div>
            </li>
            <li class="uploaderEarningsItem" style="border: 1px solid rgba(255, 162, 0, 1);width:5rem;margin: auto"></li>
            <li
                v-for="(item, index) in icomeRankList"
                :key="index"
                class="uploaderEarningsItem"
            >
              <div class="df_Yc">
                <img
                    v-if="index <= 2"
                    class="numberIcon"
                    :src="require(`@/assets/image/icon/number${index}-icon.png`)"
                    alt=""
                />
                <div class="numberBg" v-else>{{ index + 1 }}</div>
                <router-link
                    :to="{ path: '/user/index', query: { uid: item.uid } }"
                    target="_blank"
                    class="uploaderAvatar1"
                ><img :src="item.photo" alt=""
                /></router-link>
                <div>
                  <p class="f16">{{ item.nc }}</p>
                </div>
              </div>
              <p class="f16">￥{{ item.income.toFixed(2)}} </p>
            </li>
          </ul>
        </div>
      </div>
      <div class="right">
        <img src="../../assets/image/footerLeftLogo.png" class="rightContentBox"/>
        <router-link
            :to="{ path: '/sign/index' }"
            target="_blank"
        >
        <img src="../../assets/image/rightBtn.png" class="rightBtnBox" alt="">
        </router-link>
      </div>
    </div> -->
  </div>
</template>
<script>
import SlideShow from '@/components/SlideShow'
import IndexSearch from '@/components/IndexSearch'
import IndexAD from '@/components/IndexAD'
import IndexFloor from '@/components/IndexFloor'
import {getBanner} from '@/Api'
import { mapGetters } from 'vuex'
// import { incomeRankListNew2 } from "@/Api";
export default {
  name: 'HomeIndex',
  data() {
    return {
      bannerHeight: 0,
      floorData: [],
      bannerList: [],
      tagFormSon:{},
      adList:[],
      icomeRankList:[]
    }
  },
  components: {
    SlideShow,
    IndexSearch,
    IndexAD,
    IndexFloor
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background:#FFFFFF')
  },
  created() {
    this.initData()
    //判断是否是绑定账号
    if(this.$route.query.bindCode){
      this.$login({bind:1,query:this.$route.query})
    }

    // incomeRankListNew2().then((res) => {
    //   this.icomeRankList = res.data
    // })
  },
  methods: {
    initData() {
      this.floorData = [
        {
          type: 1,
          title: '知设课程',
          explain: 'TypeCourse',
          morePath: '/typeCourse/index'
        },
        {
          type: 2,
          title: 'SU模型',
          explain: 'SU',
          morePath: '/su/index'
        },
        {
          type: 3,
          title: '3D模型',
          explain: '3D',
          morePath: '/max/index'
        },
        {
          type: 4,
          title: 'PS素材',
          explain: 'PS',
          morePath: '/ps/index'
        },
        {
          type: 5,
          title: '方案文本',
          explain: 'SchemeText',
          morePath: '/schemeText/index'
        },
        {
          type: 6,
          title: '明星老师 · 行业大咖',
          explain: 'TypeCourse',
          // morePath: '/typeCourse/index'
        },
        {
          type: 7,
          title: '常用软件',
          explain: 'CommonSoftware',
          // morePath: ''
        },
        {
          type: 8,
          title: '销售榜单',
          explain: 'CourseSalesList',
					noTitle: true,
					subset: [
						{
							type: 1,
							title: '课程销售榜单',
							explain: 'CourseSalesList',
							noTitle: true,
						}, {
							type: 2,
							title: '素材销售榜单',
							explain: 'MaterialSalesList',
							noTitle: true
						},
					]
        },
				
        // {
        //   type: 1,
        //   title: '本周热门视频',
        //   explain: '视频',
        //   morePath: '/course/index'
        // },
        // {
        //   type: 2,
        //   title: '本周热门模型',
        //   explain: '模型',
        //   morePath: '/su/index'
        // },
        // {
        //   type: 3,
        //   title: '本周热门素材',
        //   explain: '素材',
        //   morePath: '/material/index'
        // },
        // {
        //   type: 4,
        //   title: 'UP主收益及发布作品榜',
        //   explain: 'uploader',
        // }
      ]
      const params = {
        position:1033
      }
      getBanner(params).then(res => {
        this.bannerList = res.data
      })
      //获取首页图片导航
      getBanner({position:1034}).then(res => {
        this.adList = res.data
      })
    },
    getBannerH(data) {
      this.bannerHeight = data
    },
    getTagFormSon(data){
      this.tagFormSon = data;
      this.$refs.mychild.setActiveItem(data.cate_id)
    }
  },
  computed: {
    ...mapGetters(["isLogin"]),
  },
  mounted() {

  },
  beforeDestroy() {
    document.querySelector('body').removeAttribute('style')
  }
}
</script>
<style scoped>
.main_Div {
  padding-bottom: 0.42rem;
  /* background: #F7F6FB; */
}

.indexSearchPositioner {
  position: absolute;
  width: 100%;
  min-width: 1400px;
  padding: 0 1.2rem;
  top: 0.68rem;
  z-index: 8;
  display: flex;
  align-items: center;
  /* background: #9e9e9e69; */
}
.footerBg{
  width:100%;
  height: 6.4rem;
  background-image: url("../../assets/image/footerBg.png") ;
  background-size: cover;
  background-repeat:no-repeat;
  display: flex;
}
.left{
  width:50%;
  position: relative;
}
.leftTitleBox{
  position: absolute;
  top: -0.1rem;
  left: 3.45rem;
  width: 3.51rem;
  height: 1.71rem;
  z-index: 1;
}
.leftContentBox{
  width:5.6rem;
  height:5rem;
  background: rgba(255, 242, 215, 1);
  border: 12px solid rgba(241, 155, 8, 1);
  border-radius: 24px;
  position: absolute;
  left: 2.4rem;
  top:1.28rem
}
.right{
  width:50%;
  position: relative;
}
.rightContentBox{
  width:6.26rem;
  height:5rem;
  position: absolute;
  right: 2.4rem;
  top:-0.24rem
}
.rightBtnBox{
  position: absolute;
  bottom:0.29rem;
  right: 3.72rem;
  width: 2.62rem;
  height: 0.8rem;
}
.uploaderEarningsList {
  margin: 0.4rem 0;
  color: rgba(245, 84, 56, 1);
  overflow: hidden;
}
.uploaderEarningsItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.4rem 0 0.3rem;
  margin: 0.2rem 0;
}
.uploaderAvatar img {
  width: 0.6rem;
  height: 0.6rem;
}
.uploaderAvatar1 {
  width: 0.24rem;
  height: 0.24rem;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 0.15rem;
  margin-left: 1rem;
  background: coral;
}
.uploaderAvatar1 img{
  width: 0.24rem;
  height: 0.24rem;
}
.numberIcon {
  width: 0.24rem;
  height: 0.24rem;
}
.numberBg {
  /*background: #c7c7c7;*/
  font-size: 0.16rem;
  color: rgba(245, 84, 56, 1);
  width: 0.24rem;
  height: 0.24rem;
  line-height: 0.24rem;
  text-align: center;
  border-radius: 0.04rem;
}
</style>
