<!--
 * @FilePath: \zsw_new_edition\src\components\SuList.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-23 14:04:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-03-08 14:55:08
-->
<!--模型列表组件-->
<template>
  <div style="width: 100%;display: flex">
    <div style="width: 20%">
      <router-link
          :to="{ path: '/su/index'}"
          target="_blank">
        <div class="suLogoImg">
          <img style="width: 100%;height:100%" :src="banner" alt="" />
        </div>
      </router-link>
    </div>
    <el-skeleton
        style="width: 80%"
        :loading="loading"
        animated
        :count="8"
    >
      <div slot="template">
        <el-skeleton-item
            variant="image"
            style="width: 3.2rem; height: 2.7rem; margin-bottom: 0.4rem"
        />
      </div>
      <div class="suList" v-if="suList.length > 0">
        <router-link
            :to="{ path: '/su/detail', query: { detailId: item.id } }"
            target="_blank"
            class="suItem"
            v-for="(item, index) in suList"
            :key="index"
        >
          <div class="suItemImg"
               @mouseleave="hideSuItemTitle(index)"
               @mouseover="showSuItemTitle(index)">
            <img :src="item.img" alt="" />
          </div>
          <div class="suItemTitle" :ref="index">{{ item.title }}</div>
        </router-link>
        <div style="height: 0; width: 3.2rem"></div>
        <div style="height: 0; width: 3.2rem"></div>
        <div style="height: 0; width: 3.2rem"></div>
      </div>
      <div v-else>
        <el-empty description="没有内容哦"></el-empty>
      </div>
    </el-skeleton>
  </div>

</template>

<script>
import {getBanner} from "../Api.js"
export default {
  name: "SuList",
  props: {
    suList: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
	data(){
		return{
			banner: require('../assets/image/suLogo.png'),
		}
	},
  methods:{
    showSuItemTitle(index){
      this.$refs[index][0].style.display = 'block'
    },
    hideSuItemTitle(index){
      this.$refs[index][0].style.display = 'none'
    },
  },
  created() {
		getBanner({position:1986,cate:2147}).then(res=>{
			this.banner = res.data[0].src
		})
	},
};
</script>

<style scoped>
.el-empty{
	width:100%
}
::v-deep .is-animated {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.suItemTitle {
  padding-left: 12px;
  color: #FFFFFF;
  font-size: 0.16rem;
  /*margin-top: 0.2rem;*/
  width: 100%;
  /*font-weight: 600;*/
  white-space: nowrap;
  overflow: hidden;
  text-overflow:ellipsis;
  position: absolute;
  bottom: 0;
  background:linear-gradient(360deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.01) 100%);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: none;
}

.suItem {
  width: 3.2rem;
  padding-right: 0.2rem;
  margin-bottom: 0.4rem;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  position: relative;
}
.suItem:hover {
  transform: translateY(-6px);
  -webkit-transform: translateY(-6px);
  -moz-transform: translateY(-6px);
}
.suLogoImg{
  width:3.2rem;
  height: 5.2rem;
  border-radius: 0.08rem;
  overflow: hidden;
  cursor: pointer;
}
.suItemImg {
  width: 3.2rem;
  height: 2.4rem;
  border-radius: 0.08rem;
  overflow: hidden;
}
.suItemImg img {
  width: 3.2rem;
  /* height: 2.4rem; */
}

::v-deep .suList {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>