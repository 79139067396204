<!--首页楼层组件-->
<template>
  <div class="floorBox mt60">
    <div class="floorTitleBox" v-if="!content.noTitle">
      <div class="df_Yc">
<!--        <img class="floorIcon" src="@/assets/image/icon/fire-icon.png" alt="">-->
        <span class="floorName">{{ content.title }}</span>
      </div>
      <div class="floorMore df_Yc"  v-if="content.morePath">
        <router-link style="margin-right: 0.05rem;color:#040404;" :to="{path:content.morePath}">
          查看更多
        </router-link>
        <i class="iconfont" style="color:#040404">&#xe665;</i>
      </div>
    </div>
    <div class="mt40">
      <!--     视频       -->
      <div v-if="content.type==1">
        <CourseList :courseList="hotCourse"></CourseList>
      </div>
      <!--     su模型       -->
      <div v-if="content.type==2">
        <HotSuList :suList="hotSu"></HotSuList>
      </div>
      <!--     3D模型       -->
      <div v-if="content.type==3">
        <HotMaxList :maxList="hotMax"></HotMaxList>
      </div>
      <!--     ps素材       -->
      <div v-if="content.type==4">
        <HotPsList :psList="hotPs"></HotPsList>
      </div>
      <!--     方案文本       -->
      <div v-if="content.type==5">
        <HotSchemeTextList :schemeTextList="hotSchemeText"></HotSchemeTextList>
      </div>
      <!--   明星老师，优秀设计者    -->
      <div v-if="content.type==6">
<!--        <HotTeacherList :teacherList="hostTeacher"></HotTeacherList>-->
				<HotTeacherList :hotTeacher="hotTeacher"></HotTeacherList>
      </div>
			<!-- 常用软件 -->
			<div v-if="content.type==7">
				<HotCommonSoftware :hotSoftware="hotSoftware"></HotCommonSoftware>
			</div>
			<!-- 销售榜单 -->
			<div v-if="content.type==8" class="salesList">
				<div class="sales" v-for="item in content.subset" :key="item.type">
					<HotSalesList :content="item" :hotCourseList="hotCourseList" :hotMaterialList="hotMaterialList"></HotSalesList>
				</div>
			</div>
    </div>
  </div>
</template>

<script>
import CourseList from '@/components/CourseList'
import HotSuList from '@/components/HotSuList'
import HotMaxList from "./HotMaxList";
// import MaterialList from '@/components/MaterialList'
import HotPsList from "./HotPsList";
import HotSchemeTextList from './HotSchemeTextList'
import HotTeacherList from "./HotTeacherList";
import HotCommonSoftware from "./HotCommonSoftware";
import HotSalesList from "./HotSalesList";
// import Uploader from '@/components/Uploader'
import { mapGetters } from 'vuex'
export default {
  name: "IndexFloor",
  props: ['content'],
  components: {
    CourseList,
    HotSuList,
    HotMaxList,
    HotPsList,
    HotSchemeTextList,
    HotTeacherList,
		HotCommonSoftware,
		HotSalesList,
    // Uploader
  },
  data(){
    return{
      list:[],
    }
  },
  created() {
    this.getList(this.content.type)
  },
  methods:{
    getList(type){
     if(type==1 && this.hotCourse.length == 0){ // 热门课程
        this.$store.dispatch('app/getHotCourse')
     }else if(type==2 && this.hotSu.length == 0){ // 热门SU
       this.$store.dispatch('app/getHotSu')
     }else if(type==3 && this.hotSu.length == 0){ // 热门max
       this.$store.dispatch('app/getHotMax')
     }else if(type==4 && this.hotMaterial.length == 0){ // 热门ps类型
       this.$store.dispatch('app/getHotPs')
     }else if(type==5 && this.hotSchemeText.length == 0){ // 热门方案文本类型
       this.$store.dispatch('app/getHotSchemeText')
     } else if(type==6 && this.hotTeacher.length == 0){ // 热门方案文本类型
       this.$store.dispatch('app/getHotTeacher')
     } else if(type==7 && this.hotSoftware.length == 0){ // 常用软件
       this.$store.dispatch('app/getHotSoftware')
     } else if(type==8 && this.hotCourseList.length == 0){ // 热门课程
			this.$store.dispatch('app/getHotCourseList')
			this.$store.dispatch('app/getHotMaterialList')
     } else if(type==8 && this.hotMaterialList.length == 0){ // 热门素材
       this.$store.dispatch('app/getHotMaterialList')
     }
    }
  },
  computed:{
    ...mapGetters([
      'hotCourse',
      'hotSu',
      'hotMaterial',
      'hotMax',
      'hotPs',
      'hotSchemeText',
      'hotTeacher',
			'hotSoftware',
			'hotCourseList',
			'hotMaterialList',
    ])
  }
}
</script>

<style scoped>
.salesList {
	display: flex;
	justify-content: space-between;
}
.salesList .sales {
	width: 46.5%;
}
.floorMore {
  color: #FF9E58;
  font-size: 0.14rem;
}

.floorBox {
  width: 100%;
  min-width: 1400px;
  padding: 0 1.2rem;
}

.floorTitleBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.floorIcon {
  width: 0.2rem;
  height: 0.2rem;
  margin-right: 0.1rem;
}

.floorName {
  color: #333333;
  font-size: 0.24rem;
  font-weight: bold;
}
</style>
