<template>
  <div class="container">
		<div class="softwareItem" v-for="item in hotSoftware" :key="item.id"  style="cursor:pointer" @click="toList(item.id)">
			<img :src="item.img" alt="" class="logo">
			<div class="desc">{{item.title}}</div>
		</div>
  </div>
</template>

<script>
  export default {
  name: "HotCommonSoftware",
  props:{
    hotSoftware: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
		toList(id){
			const {href} = this.$router.resolve({ path: "/software/softwareList", query: {child:id } });
			window.open(href, '_blank')
		},
	},
  data(){
    return {}
  },
}
</script>

<style scoped>
.container{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.softwareItem {
	width: 1.94rem;
	height: 1.91rem;
	border-radius: 0.08rem;
	border: 1px solid rgba(229, 229, 229, 0.5);
	margin-right: 0.24rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.softwareItem:last-child {
	margin-right: 0;
}
.logo {
	width: 0.8rem;
	height: 0.8rem;
	border-radius: 0.4rem;
	margin: 0 0 0.22rem 0;
}
.desc {
	width: 80%;
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
</style>
