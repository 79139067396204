<template>
  <div class="container">
    <div @click="toTeacherCourse(item)" v-for="(item,index) in hotTeacher" :key="item.uid" class="itemBox" @mouseover="showDetail(index+1)" @mouseleave="hideDetail(index+1)">
      <img :src="item.photo" alt="" class="userImg">
      <div class="info">
        <p class="authorName">{{item.name}}</p>
        <div class="authorBox">
          <div class="authorIcon">
            <img src="../assets/image/author/author.png" alt="">
          </div>
          <div class="authorDesc">学员数量：{{item.student_num}}</div>
        </div>
        <el-collapse-transition>
        <div class="detailDesc" v-if="showMethod(index+1)">
          <div>{{item.introduce}}</div>
<!--          <div class="btn" v-if="item.is_subscribe === 0" @click.stop="subscribeTeacher(item)">关注</div>-->
<!--          <div class="btn2" v-else @click.stop="cancelsubscribeTeacher(item)">已关注</div>-->
        </div>
        </el-collapse-transition>
      </div>
    </div>
  </div>
</template>

<script>
  import {subscribe,cancel_subscribe} from "../Api";
  import {mapGetters} from "vuex";
  import {getToken} from "../utils/auth";

  export default {
  name: "HotTeacherList.vue",
  props:{
    hotTeacher: {
      type: Array,
      default: () => [],
    },
  },
    computed: {
      ...mapGetters(["isLogin"]),
    },
  methods: {
    toTeacherCourse(item){
      const {href} = this.$router.resolve({ path: "/teacherCourse/index", query: {id:item.id } });
      window.open(href, '_blank')
    },
    subscribeTeacher(item){
      console.log(item)
      if(!this.isLogin){
        this.$login()
        return
      }
      const params = {
        teacher_id:item.id,
        token:getToken()
      }
      subscribe(params).then(res=>{
        if(res.status == 200) {
          this.$message.success('关注成功')
          this.$store.dispatch('app/getHotTeacher')
        }
      })
    },
    cancelsubscribeTeacher(item){
      if(!this.isLogin){
        this.$login()
        return
      }
      const params = {
        teacher_id:item.id,
        token:getToken()
      }
      cancel_subscribe(params).then(res=>{
        if(res.status == 200) {
          this.$message.success('成功取消关注')
          this.$store.dispatch('app/getHotTeacher')
        }
      })
    },
    showDetail(index) {
      this['show'+index] = true
    },
    hideDetail(index) {
      this['show'+index] = false
    },
    showMethod(index){
      if(index === 1){
        return this.show1
      }
      if(index === 2){
        return this.show2
      }
      if(index === 3){
        return this.show3
      }
      if(index === 4){
        return this.show4
      }
      if(index === 5){
        return this.show5
      }
      if(index === 6){
        return this.show6
      }
    }
  },
  data(){
    return{
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false
    }
  },
  mounted(){
    console.log(this.hotTeacher)
  }
}
</script>

<style scoped>
.container{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.itemBox{
  width:2.7rem;
  height:4.05rem;
  background: rgba(204, 204, 204, 1);
  border-radius: 0.16rem;
  position: relative;
  cursor: pointer;
}
.userImg{
  width:100%;
  height:100%;
	object-fit: cover;
  border-radius: 0.16rem;
}
.info{
  position: absolute;
  width: 2.2rem;
  /*height:0.59rem;*/
  bottom: 0.26rem;
  left: 0.24rem
}
.info>.authorName{
  font-size: 0.18rem;
  font-weight: 700;
  letter-spacing: 0;
  color: rgba(255, 255, 255, 1);
  text-align: left;
}
.authorBox{
  margin-top: 0.11rem;
  display: flex;
  align-items: center;
}
.authorIcon{
  width:0.15rem;
  height:0.14rem;
  margin-right: 0.08rem;
}
.authorIcon>img{
  width:100%;
  height:100%;
}
.authorDesc{
  font-size: 0.16rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
}
.detailDesc{
  text-align: left;
  letter-spacing: 1px;
  margin-top: 0.20rem;
  color: rgba(255, 255, 255, 1);
}
.detailDesc>div:first-child{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  overflow: hidden;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.btn{
  width: 2.22rem;
  height: 0.36rem;
  opacity: 1;
  background: rgba(244, 102, 0, 1);
  text-align: center;
  line-height: 0.36rem;
  margin-top: 0.24rem;
  border-radius:0.08rem;
  letter-spacing: 0.06rem;
}
.btn2{
  width: 2.22rem;
  height: 0.36rem;
  opacity: 1;
  background: lightgray;
  text-align: center;
  line-height: 0.36rem;
  margin-top: 0.24rem;
  border-radius:0.08rem;
  letter-spacing: 0.06rem;
}
</style>
