<!--首页搜索组件-->
<template>
	<div class="indexSearch">
		<div class="indexSearchList">
			<div class="indexSearchItem" v-for="(item, index) in searchTagList" :key="index"
				:class="{ active: currentTag == item }" @click="changeTag(item)">
				<span>{{ item.title }}</span>
				<div class="triangle-up"></div>
			</div>
		</div>
		<div class="df_Yc indexSearchInput" :class="{ activeBlur: isBlur }">
			<input v-model="search" type="text" @focus="focusText" ref="searchInput" @keyup.enter="searchLink"
				:placeholder="`输入关键词，搜索${searchPlaceholder}`" />
			<div class="indexSearchButton dfc" @click="searchLink">
				<img src="@/assets/image/icon/search-icon.png" alt="" />
			</div>
		</div>
		<Collapse>
			<div class="HotSearch" v-if="hotSearch" @mouseleave="mouseLeaveText">
				<div class="HotSearchTop">
					<img id="fire" src="@/assets/image/icon/fire-icon2.png" alt="" />
					<p>热搜：</p>
					<img id="refresh" src="@/assets/image/icon/refresh.png" alt="" @click="getHotSearch()" />
					<span @click="getHotSearch()">换一批</span>
				</div>
				<ul class="HotSearchDown">
					<li v-for="(item, index) in hotSearchList" :key="index">
						<router-link :to="{ path: link, query: { search: item } }">{{
              item
            }}</router-link>
					</li>
				</ul>
			</div>
		</Collapse>
	</div>
</template>

<script>
	import Collapse from "./collapse.js";
	import {
		hotSearch
	} from "@/Api";
	export default {
		name: "IndexSearch",
		components: {
			Collapse
		},
		data() {
			return {
				search: "",
				currentTag: null,
				searchPlaceholder: "",
				searchTagList: [{
						type: 1,
						title: "课程",
						cate_id: 1042,
					},
					{
						type: 2,
						title: "SU模型",
						cate_id: 1036,
					},
					{
						type: 3,
						title: "3D模型",
						cate_id: 1980,
					},
					{
						type: 4,
						title: "PS素材",
						cate_id: 1299,
					},
					{
						type: 5,
						title: "方案文本",
						cate_id: 1981,
					},
					{
						type: 6,
						title: "资料库",
						cate_id: 1037,
					},
					{
						type: 8,
						title: "贴图",
						cate_id: 2374,
					},
					{
						type: 7,
						title: "图库",
						cate_id: 1043,
					},
				],
				hotSearch: false,
				isBlur: false,
				hotSearchList: [],
				link: "/course/index",
			};
		},
		created() {
			this.initData();
			this.getHotSearch();
		},
		methods: {
			searchLink() {
				this.$router.push({
					path: this.link,
					query: {
						search: this.search
					}
				});
			},
			initData() {
				this.currentTag = this.searchTagList[0];
				this.searchPlaceholder = this.currentTag.title;
			},
			//传递导航给父元素
			changeTag(data) {
				this.currentTag = data;
				this.searchPlaceholder = data.title;
				this.$emit("tag", this.currentTag);
				if (this.currentTag.type == 1) {
					this.link = "/course/index";
				} else if (this.currentTag.type == 2) {
					this.link = "/su/index";
				} else if (this.currentTag.type == 3) {
					this.link = "/max/index";
				} else if (this.currentTag.type == 4) {
					this.link = "/ps/index";
				} else if (this.currentTag.type == 5) {
					this.link = "/schemeText/index";
				} else if (this.currentTag.type == 6) {
					this.link = "/material/index";
				} else if (this.currentTag.type == 7) {
					this.link = "/photo/index";
				} else if (this.currentTag.type == 8) {
					this.link = "/chartlet/index";
				}
				this.getHotSearch()
			},
			mouseLeaveText() {
				this.hotSearch = false;
				this.$refs.searchInput.blur();
				this.isBlur = false;
			},
			focusText() {
				this.hotSearch = true;
				this.isBlur = true;
			},
			getHotSearch() {
				const params = {
					fid: 6
				};
				if (this.currentTag.type == 1) {
					params.fid = 6;
				} else if (this.currentTag.type == 2) {
					params.fid = 561;
				} else if (this.currentTag.type == 3) {
					params.fid = 1393;
				} else if (this.currentTag.type == 4) {
					params.fid = 7;
				} else if (this.currentTag.type == 5) {
					params.fid = 1394;
				} else if (this.currentTag.type == 6) {
					params.fid = 1068;
				} else if (this.currentTag.type == 7) {
					params.fid = 1196;
				} else if (this.currentTag.type == 8) {
					params.fid = 2205;
				}
				hotSearch(params).then((res) => {
					this.hotSearchList = res.data;
				});
			},
		},
	};
</script>

<style scoped>
	.indexSearchItem.active {
		color: #ffffff;
	}

	.indexSearchList {
		display: flex;
	}

	.active .triangle-up {
		display: block;
	}

	.triangle-up {
		display: none;
		width: 0;
		height: 0;
		border-left: 0.1rem solid transparent;
		border-right: 0.1rem solid transparent;
		border-bottom: 0.1rem solid #ffffff;
		margin: 0 auto;
	}

	.indexSearch {
		width: 8.4rem;
		margin: 0 auto;
	}

	.indexSearchButton img {
		width: 0.34rem;
		height: 0.34rem;
	}

	.indexSearchButton {
		width: 1.54rem;
		height: 100%;
		cursor: pointer;
		background: #f46600;
	}

	.indexSearchInput input {
		flex: 1;
		padding-left: 0.2rem;
		height: 0.64rem;
		font-size: 0.24rem;
	}

	.indexSearchInput input::-webkit-input-placeholder {
		color: #bfbfbf;
		font-size: 0.18rem;
	}

	.indexSearchInput {
		background: #ffffff;
		width: 8.4rem;
		height: 0.64rem;
		overflow: hidden;
		border-radius: 0.04rem;
	}

	.indexSearchItem {
		cursor: pointer;
		color: #d9d9d9;
		font-size: 0.2rem;
		font-weight: bold;
		margin-right: 0.3rem;
	}

	.HotSearch {
		position: absolute;
		width: 8.4rem;
		min-height: 1.28rem;
		background: #ffffff;
		opacity: 1;
		border-radius: 0 0 0.04rem 0.04rem;
		border-top: 1px solid Gainsboro;
		opacity: 1;
	}

	.HotSearchTop {
		display: flex;
		height: 0.4rem;
	}

	.HotSearchTop #fire {
		width: 0.12rem;
		height: 0.16rem;
		margin: 0.22rem 0 0.9rem 0.2rem;
	}

	.HotSearchTop p {
		/* width: 0.42rem; */
		height: 0.2rem;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		/* line-height: 0px; */
		color: #666666;
		opacity: 1;
		margin: 0.22rem 0 0.9rem 0.1rem;
	}

	.HotSearchTop #refresh {
		cursor: pointer;
		width: 0.16rem;
		height: 0.16rem;
		margin: 0.22rem 0 0.9rem 6.58rem;
	}

	.HotSearchTop span {
		cursor: pointer;
		/* width: 0.42rem; */
		height: 0.2rem;
		font-size: 0.14rem;
		font-family: PingFang SC;
		font-weight: 400;
		/* line-height: 0px; */
		color: #666666;
		opacity: 1;
		margin: 0.22rem 0 0.9rem 0.1rem;
	}

	.HotSearchDown {
		margin: 0 0 0.2rem 0.2rem;
		list-style: none;
		display: flex;
		flex-wrap: wrap;
	}

	.HotSearchDown li {
		/* width: 0.96rem; */
		height: 0.3rem;
		background: #f7f6fb;
		opacity: 1;
		border-radius: 15px;
		padding: 0.05rem 0.2rem;
		text-align: center;
		margin-left: 0.2rem;
		font-size: 0.14rem;
		font-family: PingFang SC;
		font-weight: 400;
		/* line-height: 0px; */
		color: #333333;
		opacity: 1;
		margin-top: 0.18rem;
	}

	.activeBlur {
		border-radius: 0.04rem 0.04rem 0 0;
	}

	.transition-box {
		margin-bottom: 10px;
		width: 300px;
		height: 100px;
		border-radius: 4px;
		background-color: #42b983;
		text-align: center;
		color: #fff;
		padding: 40px 20px;
		box-sizing: border-box;
		margin-left: 520px;
	}
</style>
